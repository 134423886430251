import Str from "@/helpers/Str";

import AppBreadcrumbs from "@/components/AppBreadcrumbs";

import showErrorMessageMixin from "@/mixins/showErrorMessage.mixin";

import { mapActions } from "vuex";

export default {
  mixins: [showErrorMessageMixin],
  components: { AppBreadcrumbs },
  data() {
    return {
      testData: {
        name: "",
        minScore: 95,
      },
      questions: [],
      breadcrumbs: [
        {
          text: "Страница курсов",
          path: "/methodist/courses",
        },
        {
          text: localStorage.getItem("course-name"),
          path: `/methodist/courses/${this.$route.params.id}`,
        },
      ],
    }
  },
  computed: {
    minScore: {
      get() {
        return this.testData.minScore;
      },
      set(val) {
        const num = +val.replace(/[^\d]/g, "");

        this.testData.minScore = num < 100 ? num : 100;
      },
    },
    btnSaveDisabled() {
      return Str.empty(this.testData.name) || this.questions.length < 2;
    },
  },
  methods: {
    ...mapActions(["setNavigationText"]),
    changeQuestions(idx, idx2) {
      [this.questions[idx], this.questions[idx2]] = [
        this.questions[idx2],
        this.questions[idx],
      ];

      this.$forceUpdate();
    },
    addQuestion() {
      this.questions.push({
        description: "",
        answers: [{ text: "", correct: false }],
        correct: 0,
      });
    },
    setFocusToNewAnswer(e) {
      const parent = e.target.closest('.up-question__body');
      const inputs = parent.querySelectorAll('.el-input__inner');

      inputs[inputs.length - 1].focus();
    },
    addAnswer(e, i) {
      this.questions[i].answers.push({ text: "", correct: false });

      setTimeout(() => {
        this.setFocusToNewAnswer(e);
      }, 100);
    },
    removeQuestion(idx) {
      this.questions.splice(idx, 1);
    },
    removeAnswer(questionIdx, answerIdx) {
      this.questions[questionIdx].answers.splice(answerIdx, 1);
    },
    formattedQuestions() {
      return this.questions.map((q) => {
        const answers = q.answers;

        answers[q.correct].correct = true;

        return {
          description: q.description,
          answers,
        };
      });
    },
  },
}