<template>
  <div class="up-add-edit-test">
    <AppBreadcrumbs :breadcrumbs="breadcrumbs" />
    <el-form>
      <el-form-item label="Название теста">
        <el-input
          v-model="testData.name"
          placeholder="Введите название теста"
        />
        <el-button
          class="up-add-edit-test__remove-btn"
          type="text"
          @click="removeModalVisible = true"
        >
          Удалить тест
        </el-button>
      </el-form-item>
      <el-form-item label="Проходной балл. % верных ответов">
        <el-input v-model="minScore" placeholder="Введите проходной балл" />
      </el-form-item>
      <el-form-item
        v-for="(q, i) in questions"
        :key="i"
        class="up-add-edit-test__question up-question"
      >
        <div v-if="questions.length > 1" class="up-question__move-buttons">
          <i
            v-if="i > 0"
            class="el-icon-top"
            aria-label="Перемещение вопроса"
            role="button"
            @click="changeQuestions(i, i - 1)"
          />
          <i
            v-if="i != questions.length - 1"
            class="el-icon-bottom"
            aria-label="Перемещение вопроса"
            role="button"
            @click="changeQuestions(i, i + 1)"
          />
        </div>
        <div class="up-question__body">
          <div class="up-question__header">
            <el-input v-model="q.description" placeholder="Введите вопрос" />
            <i
              class="up-question__btn-remove el-icon-close"
              role="button"
              aria-label="Удалить вопрос"
              @click="removeQuestion(i)"
            />
          </div>
          <div
            v-for="(a, j) in q.answers"
            :key="`answer${j}`"
            class="up-question__answer-wrapper"
          >
            <el-radio v-model="q.correct" :label="j" />
            <el-input v-model="a.text" placeholder="Введите ответ" />
            <i
              class="up-question__btn-remove el-icon-close"
              role="button"
              aria-label="Удалить вопрос"
              @click="removeAnswer(i, j)"
            />
          </div>
          <el-button
            class="up-question__add-answer-btn"
            plain
            @click="addAnswer($event, i)"
          >
            Добавить вариант ответа
          </el-button>
        </div>
      </el-form-item>
    </el-form>
    <el-button type="primary" @click="addQuestion">Добавить вопрос</el-button>
    <footer class="up-add-edit-test__footer">
      <router-link
        class="el-button el-button--danger"
        :to="`/methodist/courses/${$route.params.id}`"
      >
        Отменить
      </router-link>
      <el-button
        type="success"
        @click="editHandler"
        :disabled="btnSaveDisabled"
      >
        Сохранить
      </el-button>
    </footer>
    <AppSimpleModal
      :visible.sync="removeModalVisible"
      title="Удаление теста"
      @action="removeTestHandler"
      @close="removeModalVisible = false"
    >
      Вы уверены, что хотите удалить тест? Это действие нельзя будет отменить!
    </AppSimpleModal>
  </div>
</template>

<script>
import AppSimpleModal from "@/components/AppSimpleModal";

import testMixin from "@/mixins/methodist/add-edit-test.mixin";

export default {
  name: "EditTest",
  mixins: [testMixin],
  components: { AppSimpleModal },
  data: () => ({
    removeModalVisible: false,
  }),
  methods: {
    setTestData(testData) {
      this.testData.minScore = testData.minScore;
      this.testData.name = testData.name;

      if (!testData.questions) return;

      this.questions = testData.questions.map((q) => {
        const correctIdx = q.answers.findIndex((a) => a.correct);
        const answers = q.answers.map((a) => ({
          text: a.text,
          correct: false,
        }));

        return {
          description: q.description,
          answers,
          correct: correctIdx,
        };
      });
    },
    async fetchTestData() {
      const cid = this.$route.params.id;
      const lid = this.$route.params.lid;

      try {
        const { data } = await this.$axios.get(
          `/constructor/course/${cid}/lesson/${lid}`
        );

        this.setTestData(data);
      } catch (e) {
        //
      }
    },
    async editHandler() {
      const questions = this.formattedQuestions();
      const cid = this.$route.params.id;

      try {
        await this.$axios.post(`/constructor/course/${cid}/lesson`, {
          id: this.$route.params.lid,
          type: "test",
          ...this.testData,
          questions,
        });

        this.$message({
          message: "Поздравляем! Тест успешно отредактирован!",
          type: "success",
        });

        this.$router.push(`/methodist/courses/${cid}`);
      } catch (e) {
        this.showErrorMessage(e);
      }
    },
    async removeTestHandler() {
      const cid = this.$route.params.id;
      const lid = this.$route.params.lid;

      try {
        await this.$axios.delete(`/constructor/course/${cid}/lesson/${lid}`);

        this.$message({
          message: "Поздравляем! Тест успешно удален!",
          type: "success",
        });

        this.$router.push(`/methodist/courses/${cid}`);
      } catch (e) {
        this.showErrorMessage(e);
      }
    },
  },
  created() {
    this.setNavigationText("Редактирование теста");

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  },
  mounted() {
    this.fetchTestData();

    this.breadcrumbs.push({ text: "Редактирование теста" });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/methodist/courses/show/new-edit-test.scss";
</style>